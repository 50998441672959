import { handleActions } from 'redux-actions';

import {
  editVisitor,
  removeVisitor,
  updateVisitors,
} from './actions';

export default handleActions(
  {
    [editVisitor]: (state, { payload: { data } }) => {
      const { id, ...rest } = data;

      return {
        ...state,
        visitors: {
          ...state.visitors,
          [id]: {
            ...state.visitors[id],
            ...rest,
          },
        },
      };
    },
    [removeVisitor]: (state, { payload: { data: { id, deletedAt, deletedBy } } }) => ({
      ...state,
      visitors: {
        ...state.visitors,
        [id]: {
          ...state.visitors[id],
          deletedAt,
          deletedBy,
          archived: true,
        },
      },
    }),
    [updateVisitors]: (state, { payload: { data } }) => ({
      ...state,
      visitors: {
        ...state.visitors,
        ...data,
      },
    }),
  }, {},
);
