/* eslint-disable no-underscore-dangle */
import { createStore, compose } from 'redux';

import { IS_PROD } from '../settings';
import rootReducer from './modules';

const devtool = window.__REDUX_DEVTOOLS_EXTENSION__;
const entityName = `Audit Module - ${IS_PROD ? 'Production' : 'Dev'}`;
const reduxDevTools = (devtool && devtool({ name: entityName })) || compose;

const store = createStore(rootReducer, reduxDevTools);

export default store;
export const { dispatch, getState } = store;
