import { createActions } from 'redux-actions';

export const {
  editVisitor,
  removeVisitor,
  updateVisitors,
} = createActions(
  'EDIT_VISITOR',
  'REMOVE_VISITOR',
  'UPDATE_VISITORS',
);
