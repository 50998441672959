import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import {
  teamsSelector,
  eventsSelector,
  guestsSelector,
  optionsSelector,
  servicesSelector,
  sessionsSelector,
  visitorsSelector,
  teamSessionsSelector,
  guestSessionsSelector,
  guestEventRequestssSelector,
  guestServicesRequestsSelector,
} from 'store/selectors';

const getData = createSelector(
  teamsSelector,
  eventsSelector,
  guestsSelector,
  optionsSelector,
  servicesSelector,
  sessionsSelector,
  visitorsSelector,
  teamSessionsSelector,
  guestSessionsSelector,
  guestEventRequestssSelector,
  guestServicesRequestsSelector,
  (
    { teams },
    { events },
    { guests },
    { options },
    { services },
    { sessions },
    { visitors },
    { teamSessions },
    { guestSessions },
    { guestEventsRequests },
    { guestServicesRequests },
  ) => ({
    teams,
    events,
    guests,
    options,
    services,
    sessions,
    visitors,
    teamSessions,
    guestSessions,
    guestEventsRequests,
    guestServicesRequests,
  }),
);

const useDataFromStore = () => useSelector(getData);

export default useDataFromStore;
