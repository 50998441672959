import { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { w3cwebsocket as W3CWebSocket } from 'websocket';

import { logger } from 'helpers';
import { wsUrl, WS_CLIENT_ID } from 'settings';

import { fetchTeam } from './handlers/teams';
import { fetchTeamSession } from './handlers/team-sessions';
import { fetchGuestSession } from './handlers/guest-sessions';

const useWebsocket = () => {
  const [isAlive, setIsAlive] = useState(false);
  const [waitingToReconnect, setWaitingToReconnect] = useState(null);
  const params = useParams();

  const noop = () => {};

  const wsClient = useRef(null);

  const onReconnect = useCallback(() => {
    if (!wsClient.current) setWaitingToReconnect(null);
    else wsClient.close();
  }, []);

  useEffect(() => {
    let userId = null;

    try {
      const { hash } = params;
      const jsonStr = atob(hash || '');
      const { teamId = null } = JSON.parse(jsonStr);
      userId = teamId;
      // eslint-disable-next-line no-empty
    } catch (e) {}

    if (waitingToReconnect || !userId) return;

    if (!wsClient.current) {
      const client = new W3CWebSocket(`${wsUrl}?id=${WS_CLIENT_ID}&userId=${userId}`);
      wsClient.current = client;

      client.onopen = () => {
        logger.info('WebSocket Server Connected');
        setIsAlive(true);
      };

      client.onmessage = ({ data }) => {
        const jsonData = JSON.parse(data);

        if (jsonData.type === 'heartbeat') {
          client.send(JSON.stringify({ type: 'heartbeat' }));
        } else if (jsonData.wsMessageId !== undefined) {
          client.send(JSON.stringify({ type: 'confirm', messageId: jsonData.wsMessageId }));
        }

        switch (jsonData.action) {
          case 'fetchTeam':
            fetchTeam({ jsonData, noop });
            break;
          case 'fetchTeamSession':
            fetchTeamSession({ jsonData, noop });
            break;
          case 'fetchGuestSession':
            fetchGuestSession({ jsonData, noop });
            break;
          default:
            break;
        }
      };

      client.onclose = () => {
        if (waitingToReconnect || !wsClient.current) return;

        logger.error('WebSocket Server Disconnected');
        setIsAlive(false);
        setWaitingToReconnect(true);

        setTimeout(() => setWaitingToReconnect(null), 5000);
      };

      client.onerror = (err) => {
        logger.error('WebSocket encountered error: ', err, 'Closing socket');
        client.close();
      };

      return () => {
        logger.log('Cleanup');
        wsClient.current = null;
        client.close();
      };
    }
  }, [waitingToReconnect, params]);

  return { wsClient: wsClient.current, isAlive, onReconnect };
};

export default useWebsocket;
