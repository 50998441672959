import React, { useState, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';

import { extendStyles } from 'helpers';
import { useOutsideClick } from 'hooks';

import styles from './styles.scss';

extendStyles(styles);

const Search = ({ name, value, style, focused, placeholder, onChange, onKeyPress, onReset, onFocus }) => {
  const [hover, setHover] = useState(false);
  const ref = createRef(null);

  useEffect(() => {
    if (!focused || !ref.current) return;
    ref.current.focus();
  }, [focused, ref]);

  useOutsideClick([ref], () => {
    onFocus(false);
  });

  const handleChange = (e) => {
    e.persist();
    onChange(e.target.value);
  };

  const handleFocus = () => onFocus(true);

  const inputStyle = style.backgroundColor ? {
    backgroundColor: style.backgroundColor,
  } : {};

  return (
    <div
      className={ styles.search }
      onMouseEnter={ () => setHover(true) }
      onMouseLeave={ () => setHover(false) }
    >
      <input
        ref={ ref }
        name={ name }
        value={ value || '' }
        style={ inputStyle }
        onClick={ handleFocus }
        onChange={ handleChange }
        onKeyDown={ onKeyPress }
        className={ styles.input }
        placeholder={ placeholder }
        autoComplete="off"
      />
      { hover && <div className={ styles.close } onClick={ onReset } /> }
    </div>
  );
};

Search.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.shape({
    backgroundColor: PropTypes.string,
  }),
  focused: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  onReset: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
};

Search.defaultProps = {
  value: null,
  style: {},
  placeholder: '',
};

export default Search;
