import React from 'react';
import PropTypes from 'prop-types';

import { extendStyles } from 'helpers';

import styles from './styles.scss';

extendStyles(styles);

const Tabs = ({ tabs, activeTab, onTabSelect }) => {
  const renderTab = (tab) => (
    <div key={ tab.id } className={ styles.get('tab', activeTab === tab.id && 'active') } onClick={ () => onTabSelect(tab.id) }>{ tab.label }</div>
  );

  return (
    <div className={ styles.tabs }>
      { tabs.map(renderTab) }
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  activeTab: PropTypes.string.isRequired,
  onTabSelect: PropTypes.func.isRequired,
};

export default Tabs;
