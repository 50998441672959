import { createSelector } from 'reselect';
import { getProps } from 'helpers';

export const visitorsContainer = ({ visitors }) => visitors;

export const visitorsSelector = createSelector(
  [visitorsContainer],
  ({ visitors }) => ({ visitors }),
);

export const visitorByIdSelector = createSelector(
  [visitorsContainer, getProps],
  ({ visitors }, { id }) => ({ visitor: visitors[id] }),
);
