import { combineReducers } from 'redux';

import auth from './auth/reducer';
import admin from './admin/reducer';
import teams from './teams/reducer';
import items from './items/reducer';
import guests from './guests/reducer';
import invoices from './invoices/reducer';
import sessions from './sessions/reducer';
import visitors from './visitors/reducer';
import guestEvents from './guest-events/reducer';
import guestServices from './guest-services/reducer';
import customProtocols from './custom-protocols/reducer';
import guestEventsRequests from './guest-events-requests/reducer';
import guestServicesRequests from './guest-services-requests/reducer';

export default combineReducers({
  auth,
  admin,
  teams,
  items,
  guests,
  invoices,
  sessions,
  visitors,
  guestEvents,
  guestServices,
  customProtocols,
  guestEventsRequests,
  guestServicesRequests,
});
